export const formNotificationItems = (response, varMethod) => {
  let notificationBarItems = []
      JSON.parse(response.data.getNotification).forEach((notification, index) => {
        if(notification.notification_action == 'ACKNOWLEDGE_AND_OPTIONAL_UPDATE' ){
            // this case is handled as a different notification
            return
        }

        let notificationType = "info"
        if(notification.notification_type == 'FRISCO_2PR_ALIAS_VALIDATION_ERROR'){
          notificationType = "error"
        }else if(notification.notification_type == 'ALIAS_PATROL_JOB_STATUS_MISMATCH'){
          notificationType = "error"
        }
        notificationBarItems.push(
          {
            type: notificationType,
            dismissible: true,
            dismissLabel: "Dismiss message",
            content: notification.notification_message,
            id: `message_${index}`,
            onDismiss: () =>
            varMethod(items =>
                items.filter(item => item.id !== `message_${index}`)
              )
          }
        )
      })
      varMethod(notificationBarItems)
}

export const formActionableNotificationItems = (response, varMethod) => {
  let notificationBarItems = []
      JSON.parse(response.data.getNotification).forEach((notification, index) => {
        if(notification.notification_action != 'ACKNOWLEDGE_AND_OPTIONAL_UPDATE' ){
            return
        }
        if(notification.info && notification.info.includes(":")){
          // let info = notification.info.split(":")
          // if(info.length > 1){
          //   notification['info'] = `${info[0]} (${info[1]})`
          // }
        }
        notificationBarItems.push(notification)
      })
      varMethod(notificationBarItems)
}