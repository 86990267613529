import { API, graphqlOperation } from 'aws-amplify';
import { FLEXICO_TEMPLATE_KEYS } from 'src/Ateam-BI/FlexiCo/Constants';
import { getQueryInput } from 'src/Ateam-BI/Templates/Utils/AppActivityUtils';
import { configureFlexicoAppSync, configureGenericAppSync } from 'src/context/AuthContextUtility';


export const loadFlexicoTemplates = async(appId, onSuccess, onFailure) => {
    var mQryFlexico = `
    query MyQuery {
      listFlexicoTemplates(app_id: "${appId}") {
        label
        app_id
        approval_days
        approval_required
        created_by
        created_time_stamp
        description
        approvers {
          alias
          column_index
          type
        }
        status
        parameters {
          field_id
          field_type
          field_values
          label
        }
        file_types
        flex_id
        group_id
        last_updated_by
        last_updated_time_stamp
        next_step
        step_function_name
        template_name
        uploaders {
          alias
          type
        }
      }
    }  
  `;

    executeQuery(mQryFlexico, onSuccess, onFailure)
}

export const getFlexicoTemplate = async(flexId, onSuccess, onFailure) => {
    var mQryFlexico = `
    query MyQuery {
        getFlexicoTemplate(flex_id: "${flexId}") {
          app_id
          approval_days
          approval_required
          approvers {
            alias
            column_index
            type
          }
          created_by
          created_time_stamp
          critical_data
          description
          file_types
          group_id
          label
          last_updated_by
          last_updated_time_stamp
          next_step
          flex_id
          parameters {
            field_id
            field_type
            field_values
            label
          }
          sox_approved
          sox_evidence
            trigger_type
            upload_mandatory
          status
          step_function_name
          template_name
          uploaders {
            alias
            type
          }
        }
      }
       
  `;

    executeQuery(mQryFlexico, onSuccess, onFailure)
}

export const deleteFlexicoTemplate = async(flexId, onSuccess, onFailure) => {
    var mQryFlexico = `
    mutation MyMutation {
        deleteFlexicoTemplate(input: {flex_id: "${flexId}"}) {
          message
        }
      }
  `;

    executeQuery(mQryFlexico, onSuccess, onFailure)
}

const cleanForMutation = (data) => {
    if (data?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.parameters)) {
        let cleanedParams = []
        for (let i = 0; i < data[FLEXICO_TEMPLATE_KEYS.parameters].length; i++) {
            let param = data[FLEXICO_TEMPLATE_KEYS.parameters][i]
            if (param?.hasOwnProperty('field_tokens') ) {
                delete param['field_tokens']
            }
            if (param?.hasOwnProperty('limit') ) {
                delete param['limit']
            }
            if (param?.hasOwnProperty('typed_value')) {
                delete param['typed_value']
            }
            cleanedParams.push(param)
        }
        data[FLEXICO_TEMPLATE_KEYS.parameters] = cleanedParams
    }
    return data
}
export const createFlexicoTemplate = (data, onSuccess, onFailure) => {
    data = cleanForMutation(data)
    var mQryFlexico = `mutation MyMutation {
        createFlexicoTemplate(input: {${getQueryInput(data, [FLEXICO_TEMPLATE_KEYS.last_updated_time_stamp, FLEXICO_TEMPLATE_KEYS.created_time_stamp])}}
        ) {
            app_id
            approval_days
            approval_required
            approvers {
              alias
              column_index
              type
            }
            created_by
            created_time_stamp
            critical_data
            description
            file_types
            flex_id
            group_id
            label
            last_updated_by
            last_updated_time_stamp
            next_step
            sox_approved
            sox_evidence
            trigger_type
            upload_mandatory
            status
            step_function_name
            template_name
            uploaders {
              alias
              type
            }
            parameters {
              field_id
              field_type
              field_values
              label
            }
        }
      } 
  `;

    //   console.log(mQryFlexico)
    executeQuery(mQryFlexico, onSuccess, onFailure)

}

export const updateFlexicoTemplate = (data, onSuccess, onFailure) => {
    data = cleanForMutation(data)
    var mQryFlexico = `mutation MyMutation {
        updateFlexicoTemplate(input: {${getQueryInput(data, [FLEXICO_TEMPLATE_KEYS.last_updated_time_stamp, FLEXICO_TEMPLATE_KEYS.created_time_stamp, FLEXICO_TEMPLATE_KEYS.created_by])}}
        ) {
            app_id
            approval_days
            approval_required
            approvers {
              alias
              column_index
              type
            }
            created_by
            created_time_stamp
            critical_data
            description
            file_types
            flex_id
            group_id
            label
            last_updated_by
            last_updated_time_stamp
            next_step
            sox_approved
            sox_evidence
            trigger_type
            upload_mandatory
            status
            step_function_name
            template_name
            uploaders {
              alias
              type
            }
            parameters {
              field_id
              field_type
              field_values
              label
            }
        }
      } 
  `;

    //   console.log(mQryFlexico)
    executeQuery(mQryFlexico, onSuccess, onFailure)

}

export const createFlexicoTransaction = (data, onSuccess, onFailure) => {
    var mQryFlexico = `mutation MyMutation {
        createFlexicoTransaction(input: {${getQueryInput(data, [])}}) {
          appId
          bucketName
          createdTimestamp
          errorMessage
          filePath
          fileId
          fileExt
          flexId
          jobStartTime
          jobStatusModificationTimestamp
          lastUpdatedTimestamp
          status
          stepInstanceID
          stepToken
          uploadedTimestamp
          uploadedBy
          parameters
        }
      }
      `;

    //   console.log(mQryFlexico)
    executeQuery(mQryFlexico, onSuccess, onFailure)

}

export const getNextTokenQuery = (nextToken) => {
    if (nextToken) {
      return `, after: "${nextToken}"`
    } else {
      return ''
    }
  
  }
export const listFlexicoTransactionsByFlexId = (appId,flexId,limit, nextToken, onSuccess, onFailure) => {
    var mQryFlexico = `query MyQuery {
        listFlexicoTransactionsByFlexId(appId: "${appId}", flexId: "${flexId}" ,first: ${limit} ${getNextTokenQuery(nextToken)}) {
          nextToken
          items {
            appId
          bucketName
          createdTimestamp
          errorMessage
          filePath
          fileId
          fileExt
          flexId
          jobStartTime
          jobStatusModificationTimestamp
          lastUpdatedTimestamp
          status
          stepInstanceID
          stepToken
          uploadedTimestamp
          uploadedBy
          parameters
          approvalModificationTime
          approvalRequestedTime
          approvalStatus
          approvedBy
          approvalId
          outputResultsS3
          }
        }
      }
      `;

    //   console.log(mQryFlexico)
    executeQuery(mQryFlexico, onSuccess, onFailure)

}

export const getFlexicoTransaction = (fileId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getFlexicoTransaction(fileId: "${fileId}") {
      fileId
      flexId
      appId
    }
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const executeQuery = async(mQry, onSuccess, onFailure) => {
    await configureFlexicoAppSync()
    API.graphql(graphqlOperation(mQry)).then(result => {
        if (onSuccess) {
            configureGenericAppSync()
            onSuccess(result)
        }
    }, function (error) {
        if (onFailure) {
            configureGenericAppSync()
            onFailure(error)
        }
    });
    await configureGenericAppSync()
}