import React, { useState, useEffect } from "react";
import {
  Select
} from "@amzn/awsui-components-react";

const CustomSelectAlias = (props) => {
  const [options, setOptions] = useState(props.options)
  const [aliasesLoading, setAliasesLoading] = useState(props?.aliasesLoading)
  const makeSelectObject = () => {
    let value = props.getValue(props.field, props.subKey)
    if(value == null){
      return null
    }else{
      return { label: value, value: value }
    }
  }
  useEffect(() =>{
    setOptions(props.options)
  },[props.options])

  useEffect(() =>{
    setAliasesLoading(props?.aliasesLoading)
  },[props?.aliasesLoading])

  return (
    <Select
      selectedOption={makeSelectObject()}
      onChange={({ detail }) =>
        props.updateValue(props.field, [detail.selectedOption.value])
      }
      placeholder="Select Alias"
      options={options}
      statusType={aliasesLoading ? "loading" : "finished"}
      filteringType="manual"
      loadingText="Loading aliases"
      empty="No valid alias to recommend for entered text"
      onLoadItems ={({detail})=>{
        
        props.getOptions(detail?.filteringText)
        
      }}
    />
  )
}

export default CustomSelectAlias;


