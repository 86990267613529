import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Select,
  Icon,
  Spinner,
  ColumnLayout,
  Input,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import { select_default_value_all } from "./Constants";
import {setFilterOptions, setFilterOptionsWithAll} from "./FilterUtilities"
import { filter } from "d3";
import { setCurrentAppId } from "../../store/userAuthSlice";


const EmailSubcribtion = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const [items, setItems] = useState([]);
  const [processingStatus, setProcessingStatus] = useState("info");
  const [currentStatusId, setCurrentStatusId] = useState("");
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const [currentId, setCurrentId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);

  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [filterEmail, setFilterEmail] = useState('');
  const [filterRegion, setFilterRegion] = useState(select_default_value_all);
  const [editEmail, setEditEmail] = useState('');
  const [editRegion, setEditRegion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editWindowSuccessMessage, setEditWindowSuccessMessage] = useState('');
  const [editWindowErrorMessage, setEditWindowErrorMessage] = useState('');
  const [toolsHide, setToolsHide] = useState(true);
  const [filterRegionList, setFilterRegionList] = useState([]);
  const [filterRegionListWithAll, setFilterRegionListWithAll] = useState([]);


  useEffect(() => {
    
    loadData();


    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);


    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const loadRegionMap = () => {
    let map = {}
    filterRegionListWithAll.forEach((d) => map[d.label] = d)
    return map;
  }
  const [regionMap, setRegionMap] = useState(loadRegionMap());

  useEffect(() => {
    setRegionMap(loadRegionMap())
  }, [filterRegionListWithAll])

  const [columnDefs] = useState([
    { field: "id", sortable: true, resizable: true },
    { field: "region", width: "150px", sortable: true, resizable: true },
    { field: "email", width: "150px", sortable: true, resizable: true },
    { field: "created_by", width: "150px", resizable: true },
    { field: "created_time", width: "200px", resizable: true },
    { field: "updated_by", width: "150px", resizable: true },
    { field: "updated_time", width: "300px", resizable: true }
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      let selectedRow = selectedRows[0]
      setEditEmail(selectedRow?.email)
      setEditRegion(regionMap[selectedRow?.region])
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "TED", href: "/TedHome" },
    { text: "Review", href: "" },
  ];

  const filterItems = (itemsToFilter) => {

    let filtersApplied = getAppliedFiltersMap()
    Object.keys(filtersApplied).forEach(key => {
      if (filtersApplied[key] !== 'All') {
        itemsToFilter = itemsToFilter.filter(d => d[key] === filtersApplied[key]);
      }
    })
    return itemsToFilter
  }

  const getAppliedFiltersMap = () => {
    let filtersApplied = {}
    if (filterRegion && filterRegion?.value) {
      filtersApplied['region'] = filterRegion?.value
    }
    if (filterEmail) {
      filtersApplied['email'] = filterEmail
    }
    return filtersApplied
  }

  const resetEditWindow = () => {
    setEditWindowErrorMessage('')
    setEditWindowSuccessMessage('')
    setEditEmail('')
    setEditRegion('')
  }

  const resetEditWindowValues = () => {
    setEditEntity('')
    setEditRegion('')
  }

  const resetEditWindowMessages = () => {
    setEditWindowErrorMessage('')
    setEditWindowSuccessMessage('')
  }

  const loadData = async () => {
    setShowLoadingIcon(true);

    var regionQry = `query MyQuery {
      listRegionTriggerMapping {
        region
      }
    }`;
  

  // console.log(mQry)
  let regionQryResponse = await API.graphql(graphqlOperation(regionQry));
  let regionJson = regionQryResponse.data.listRegionTriggerMapping;

    var mQry =
      `query MyQuery {
          listRegionEmail{
              id
            email
            created_time
            created_by
            region
            updated_by
            updated_time
          }
        }`;

    // console.log(mQry)   
    setShowLoadingIcon(true); 
    await executeQuery(mQry, function onSuccess(result) {
      setErrorMessage("")
      let qResultJSON = result.data.listRegionEmail;
      setFilterRegionList(setFilterOptions(regionJson, 'region'))
      setFilterRegionListWithAll(setFilterOptionsWithAll(regionJson, 'region'))
      setItems(filterItems(qResultJSON));
      setShowLoadingIcon(false);
    }, function onFailure(error) {
      setErrorMessage(error?.errors[0]?.message)
      setShowLoadingIcon(false);
    })
  };

  const executeQuery = async (mQry, onSuccess, onFailure) => {
    
    // console.log(mQry)    
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      onSuccess(result)
    }, function (error) {
      onFailure(error)
    });

  };

  const detailView = (
    <Container>
      {selectedItem.length != 0 ? (
        <ColumnLayout columns={3}>
          <Box variant="awsui-key-label">
            File id
            <Box>{selectedItem.id}</Box>
          </Box>
        </ColumnLayout>
      ) : (
        <Box variant="p" color="text-status-error">
          Select any row from above table
        </Box>
      )}
    </Container>
  );

  const insertEntry = () => {
    resetEditWindowMessages()
    if (!editRegion || editEmail === '') {
      setEditWindowErrorMessage("Enter valid inputs")
      return
    }
    // check if entry already exists in local data
    let found = false

    for(let i = 0; i < items.length; i++){
      if (editRegion?.value === items[i]?.region && editEmail === items[i]?.email) {
        found = true
        break
      }
    }

    if (found) {
      setEditWindowErrorMessage("Entry already exists")
    } else {
      setEditWindowErrorMessage()
      let qry = `mutation MyMutation{
        insertRegionEmail(region: "`+ editRegion.value + `", email: "` + editEmail + `", userId: "` + userId + `"){
            message
        }
      }`
      setShowLoadingIcon(true); 
      executeQuery(qry, function onSuccess(result) {
        let qResultJSON = result.data.insertRegionEmail;
        if (qResultJSON?.message?.toLowerCase() === 'success') {
          setEditWindowSuccessMessage(`${editEmail} at ${editRegion?.value} inserted successfully`)
        } else {
          setEditWindowErrorMessage(`Insertion failed for ${editEmail} at ${editRegion?.value}`)
        }
        setShowLoadingIcon(false); 
        loadData()
      }, function onFailure(error) {
        setEditWindowErrorMessage(`Insertion failed for ${editEmail} at ${editRegion?.value}. ${error?.errors[0]?.message}`)
        setShowLoadingIcon(false); 
      })
    }
  }

  const deleteEntry = () => {
    resetEditWindowMessages()
    if (!editRegion || editEmail === '') {
      setEditWindowErrorMessage("Enter valid inputs")
      return
    }
    // check if entry exists in local data
    let found = false
    let selectedId = null

    for(let i = 0; i < items.length; i++){
      if (editRegion?.value === items[i]?.region && editEmail === items[i]?.email) {
        found = true
        selectedId = items[i]?.id
        break
      }
    }

    if (!found) {
      setEditWindowErrorMessage("Cannot delete, entry does not exist in the data")
    } else {
      setEditWindowErrorMessage()
      let qry = `mutation MyMutation{
        deleteRegionEmail(id: `+ selectedId + `){
            message
        }
      }`
      setShowLoadingIcon(true); 
      executeQuery(qry, function onSuccess(result) {
        let qResultJSON = result.data.deleteRegionEmail;
        if (qResultJSON?.message?.toLowerCase() === 'success') {
          setEditWindowSuccessMessage(`${editEmail} at ${editRegion?.value} deleted successfully`)
        } else {
          setEditWindowErrorMessage(`Delete failed for ${editEmail} at ${editRegion?.value}`)
        }
        loadData()
        setShowLoadingIcon(false); 
      }, function onFailure(error) {
        setEditWindowErrorMessage(`Delete failed for ${editEmail} at ${editRegion?.value}. ${error?.errors[0]?.message}`)
        setShowLoadingIcon(false); 
      })
    }
  }

  const resetFilter = () => {
    setFilterRegion(select_default_value_all)
    setErrorMessage('')
    setFilterEmail('')
  }

  const content = (
    <SpaceBetween direction="vertical" size="m">
      {isAuthorized ? (
        <Box>
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {/* <Button variant='normal' onClick={()=>openButtonClick()}>View Details</Button> */}
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >

                    </Button>
                  </SpaceBetween>
                }
                description="Below Table shows the email subscription list, to the region"
              >
                Enitity ETB Email subscription
              </Header>
            }
          >

            <div
              className="ag-theme-alpine"
              style={{ height: 150, width: "100%" }}
            >
              <Box>
                <ColumnLayout columns={4}>
                  <Box>
                    <Box variant="awsui-key-label">Regions</Box>
                    <Select
                      selectedOption={filterRegion}
                      options={filterRegionListWithAll}
                      onChange={({ detail }) => {
                        setFilterRegion(detail.selectedOption);
                      }}
                    ></Select>
                  </Box>
                  <Box>
                    <Box variant="awsui-key-label">Email</Box>
                    <Input value={filterEmail} onChange={({ detail }) => setFilterEmail(detail.value)} ></Input>
                  </Box>
                </ColumnLayout>
                <br></br>
                <Box></Box>
                <SpaceBetween size='m' direction='horizontal'>
                  <Button variant='normal' onClick={resetFilter}>Reset</Button>
                  <Button variant='primary' onClick={loadData}>Filter</Button>
                </SpaceBetween>
                {errorMessage && (
                  <Box variant="p" color="text-status-error">
                    {errorMessage}
                  </Box>
                )}
              </Box>
              <br></br>

            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={items}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="single"
                animateRows={true}
                rowHeight={30}
                checkboxSelection={true}
                onSelectionChanged={onSelectionChanged}
              ></AgGridReact>
            </div>
          </Container>
          <p></p>

          <p></p>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Manage Entity Email subscription </h2>
          {selectedItem.length == 0 ? (
            <small>Select a row to insert/delete</small>
          ) : (
            <div></div>
          )}
        </div>
      }
    >
      <Box>
        <SpaceBetween size="m">
          <Box variant="awsui-key-label">{selectedItem.Description}</Box>
          <Box>
            <Box variant="awsui-key-label">Region</Box>
            <Select
              selectedOption={editRegion}
              options={filterRegionList}
              onChange={({ detail }) => {
                setEditRegion(detail.selectedOption);
              }}
            ></Select>
          </Box>
          <Box>
            <Box variant="awsui-key-label">Email</Box>
            <Input value={editEmail} onChange={({ detail }) => setEditEmail(detail.value)} ></Input>
          </Box>
          <Box>
            <SpaceBetween size='m' direction='horizontal'>
              <Button variant='normal' onClick={insertEntry}>Insert</Button>
              <Button variant='primary' onClick={deleteEntry}>Delete</Button>
            </SpaceBetween>

          </Box>
          <Box>
            {editWindowSuccessMessage && (
              <Box variant="p" color="text-status-success">
                {editWindowSuccessMessage}
              </Box>
            )}
            {editWindowErrorMessage && (
              <Box variant="p" color="text-status-error">
                {editWindowErrorMessage}
              </Box>
            )}
          </Box>
        </SpaceBetween>
      </Box>
    </HelpPanel>
  );
  const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: "Split panel preferences",
    preferencesPositionLabel: "Split panel position",
    preferencesPositionDescription:
      "Choose the default split panel position for the service.",
    preferencesPositionSide: "Side",
    preferencesPositionBottom: "Side",
    preferencesConfirm: "Confirm",
    preferencesCancel: "Cancel",
    closeButtonAriaLabel: "Close panel",
    openButtonAriaLabel: "Open panel",
    resizeHandleAriaLabel: "Resize split panel",
  };
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/EmailSubscription" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={helpContent}
      toolsOpen={isAuthorized ? toolsHide : false}
      onToolsChange={() => { setToolsHide(!toolsHide) }}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default EmailSubcribtion;
