import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from "react-router-dom";

import './styles.scss';

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Alert, ExpandableSection, Tiles,
    Container, Header, ColumnLayout, Spinner, Link, Tabs, Button, Table, SpaceBetween, Modal, FileUpload, Select, Multiselect, Input, Grid
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import { useAuth } from "../../context/AuthContextProvider";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Amplify, { Storage } from 'aws-amplify';


import { setCurrentAppId } from '../../store/userAuthSlice';
import * as XLSX from 'xlsx';
import { createFriscoTransactions } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { FLEXICO_TRANSACTION_STATUS, FLEXICO_TEMPLATE_KEYS } from './Constants';
import { configureFlexicoS3, configureFriscoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isUploaderAllowed } from '../Frisco/AccessUtilities';
import { createFlexicoTransaction, getFlexicoTemplate } from 'src/utilities/FlexicoAPIs';
import { getFlexicoS3Config } from 'src/utilities/AWSServices';
import { FLEXICO_STEP_FUNCTION_CONFIG } from 'src/constants/AppConstants';
import { checkIfPortalAdmin, checkIfSupportAdmin } from 'src/checkPageAccess';

export default (props) => {
    const userId = useSelector((globalState) => globalState.auth.userId)
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)

    const history = useHistory();
    const userAuthenticationDetails = useAuth();
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    let { id } = useParams();
    let { fid } = useParams();
    const [flexicoTemplateData, setFlexicoTemplateData] = useState({})
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const dispatch = useDispatch()
    const [activeTabId, setActiveTabId] = useState("first");
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);
    const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [missingFields, setMissingFields] = useState(new Set());
    const [inputFiles, setInputFiles] = useState([]);
    const [supportedFormats, setSupportedFormats] = useState('.csv');
    const [loadingMessage, setLoadingMessage] = useState('loading, please wait..');
    const [showUploadBtn, setShowUploadBtn] = useState(false)
    const [fileReference, setFileReference] = useState('');
    const [inputSheets, setInputSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [backUrl, setBackUrl] = useState(null)
    const [uploadParameters, setUploadParameters] = useState({})
    const [enableTrigger, setEnableTrigger] = useState(false)
    useEffect(() => {
        configureFlexicoS3()
        if (fid) {
            loadData();
        }
        if (id) {
            dispatch(setCurrentAppId(id))
        }
        setBackUrl(getParam('back'))
        return () => {
            // this now gets called when the component unmounts
            configureGenericS3()
        };
    }, []);



    useEffect(() => {
        configureFlexicoS3()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [isCurrentAppAuthorised]);

    useEffect(() => {
        if (isAuthorized === true || isAuthorized === false) {
            setIsCheckingAccess(!isCheckingAccess);
        }
    }, [isAuthorized]);

    useEffect(() => {
        checkIfFormIsSubmittable()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [uploadParameters, inputFiles, flexicoTemplateData]);

    useEffect(() => {
        onUpload(inputFiles)
        return () => {
            // this now gets called when the component unmounts
        };
    }, [inputFiles]);

    const getParam = (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
    }
    const loadData = () => {
        setLoadingMessage("loading, please wait...")
        setShowLoadingIcon(true);
        setShowUploadBtn(false)
        uploadParameters['triggered_by'] = userId
        setUploadParameters({...uploadParameters})
        // setProcessData()
        getFlexicoTemplate(fid, async function onSuccess(response) {
            let res = response.data.getFlexicoTemplate
            setFlexicoTemplateData(res)
            setShowLoadingIcon(false);
            setSupportedFormats(getSupportedFormats(res))
            let isAllowed = await checkIfPortalAdmin(userId, currentStage)
            if(!isAllowed){
                isAllowed = await checkIfSupportAdmin(userId, currentStage)
            }
            if(!isAllowed){
                isAllowed = await isUploaderAllowed(res, userId, currentStage)
            }
    
            if (!isAllowed) {
                setShowLoadingIcon(false);
                setShowUploadBtn(false)
                setErrorMessage("Cannot execute. Please check with the owner of the FlexiCo template for access.")
            } else {
                setShowLoadingIcon(false);
                setShowUploadBtn(true)
            }
            setIsAuthorized(isAllowed)
        }, function onFailure(response) {
            setShowLoadingIcon(false);
            setErrorMessage("Could not load template information")
            setShowUploadBtn(true)
        })

    }

    const getAllowedFileFormats = () => {
        if (flexicoTemplateData && flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.file_types) && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.file_types]) {
            return flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.file_types]
        }
        return []
    }

    const isAllowedFileFormat = (format, allowedFileFormats) => {
        let isAllowed = false
        if (!format) {
            isAllowed = false
        }
        let supportedFormats = []
        for (let i = 0; i < allowedFileFormats.length; i++) {
            if (allowedFileFormats[i] == 'CSV') {
                supportedFormats.push('text/csv')
                supportedFormats.push('text/plain')
                supportedFormats.push('text/x-csv')
                supportedFormats.push('application/vnd.ms-excel')
                supportedFormats.push('application/csv')
                supportedFormats.push('application/x-csv')
                supportedFormats.push('text/comma-separated-values')
                supportedFormats.push('text/x-comma-separated-values')
                supportedFormats.push('text/tab-separated-values')
            } else if (allowedFileFormats[i] == 'Excel') {
                supportedFormats.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                supportedFormats.push('application/vnd.ms-excel.sheet.macroenabled.12')
            }
        }
        isAllowed = supportedFormats.some(item => format === item);
        return isAllowed
    }
    const validateInputFileFormat = (file) => {
        let allowedFileFormats = getAllowedFileFormats()
        let isAllowed = isAllowedFileFormat(file?.type, allowedFileFormats)
        if (!file?.type) {
            throw new Error("Please upload an input file")
        }
        if (!isAllowed) {
            throw new Error(`File format is not allowed : ${file?.type}`);
        }
        return isAllowed
    }


    const resetValidationErrorLogs = () => {
        setMissingFields(new Set())
    }


    const validateFileSize = (fileDesc) => {
        if (fileDesc.size / 1000000 >= 200) {
            throw ({ error: 'FILE MAX LIMIT', message: "Max file limit is 200MB" })
        } else {
            setErrorMessage()
        }
    }

    const checkIfFormIsSubmittable = () => {
        let uploadParametersCount = 0
        Object.keys(uploadParameters).forEach((key) => {
            if(key=='triggered_by'){
                return
            }
            if (uploadParameters[key]?.length > 0) {
                uploadParametersCount = uploadParametersCount + 1
            }
        })
        if(!flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type)){
            setEnableTrigger(false)
        }else if(flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file with parameters' ){
            setEnableTrigger( ((inputFiles && inputFiles.length > 0) || !flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.upload_mandatory]) && uploadParametersCount >= flexicoTemplateData?.parameters?.length)
        }else if(flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file only' ){
            setEnableTrigger(inputFiles && inputFiles.length > 0)
        }else if(flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Parameters only' ){
            setEnableTrigger(uploadParametersCount >= flexicoTemplateData?.parameters?.length)
        }else{
            setEnableTrigger(true)
        }
    }

    const onUpload = async (inputFiles) => {
        try {
            setLoadingMessage("loading file, please wait...")
            setShowLoadingIcon(true);
            setErrorMessage('')
            setIsFileSelected(true)
            setShowFileNotSeleted(false)
            resetValidationErrorLogs()
            setShowLoadingIcon(false);
            checkIfFormIsSubmittable()
        } catch (error) {
            setErrorMessage()
            setLoadingMessage("")
            setShowLoadingIcon(false)
        }

    }

    const ModalOkClick = () => {
        history.push(`/App/${id}/flexico/${fid}/history`)
    }


    const getSupportedFormats = (input = flexicoTemplateData, defValue = '.xlsx') => {
        let returnValue = new Set()
        returnValue.add(defValue)
        if (input && input.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.file_types)) {
            input[FLEXICO_TEMPLATE_KEYS.file_types].forEach((item) => {
                if (item == 'CSV') {
                    returnValue.add('.csv')
                } else if (item == 'Excel') {
                    returnValue.add('.xls')
                    returnValue.add('.xlsx')
                    // returnValue.add('.xlsb')
                    returnValue.add('.xlsm')
                    // returnValue.add('.xltx')
                    // returnValue.add('.xltm')
                } else {
                    returnValue.add(defValue)
                }
            })
        }
        return Array.from(returnValue).join(",")
    }


    const constructSelectedOptions = (values) => {
        let selectedOptions = []
        for(let i=0;i<values.length;i++){
            selectedOptions.push({label: values[i], value: values[i]})
        }
        return selectedOptions
    }
    const getParameterItems = (value, index) => {
        let element = <></>
        if (value.label?.startsWith('flexico_internal')) {
            uploadParameters[value.label] = value.field_values
            return element
        }

        if (value.field_type == 'select') {
            let options = []
            value.field_values?.forEach((field_value) => {
                options.push({ label: field_value, value: field_value })
            })
            element = <Select
                selectedOption={uploadParameters[value.label]?.length > 0 ? { label: uploadParameters[value.label], value: uploadParameters[value.label] } : ''}
                onChange={({ detail }) => {
                    uploadParameters[value.label] = detail.selectedOption.label
                    setUploadParameters({ ...uploadParameters })
                }

                }
                options={options}
            />
        } else if (value.field_type == 'multi_select') {
            let options = []
            value.field_values?.forEach((field_value) => {
                options.push({ label: field_value, value: field_value })
            })
            element = <Multiselect
                selectedOptions={uploadParameters[value.label] ? constructSelectedOptions(uploadParameters[value.label]): []}
                onChange={({ detail }) => {
                    let parsedOptions = []
                    detail.selectedOptions?.forEach((selectedOption)=>{
                        parsedOptions.push(selectedOption.label)
                    })
                    uploadParameters[value.label] = parsedOptions
                    setUploadParameters({ ...uploadParameters })
                }
                }
                options={options}
                placeholder="Choose options"
            />
        } else if (value.field_type == 'input') {
            element = <Input
                onChange={({ detail }) => {
                    uploadParameters[value.label] = detail.value
                    setUploadParameters({ ...uploadParameters })
                }}
                value={uploadParameters[value.label]}
            />
        } else if (value.field_type == 'fixed_inputs') {
            uploadParameters[value.label] = value.field_values
            element = <Box>{value.field_values?.join(',')}</Box>
        }

        return <Box key={value.label}>
            <SpaceBetween direction='vertical' size='s' >
                <Box variant="awsui-key-label">{value.label}</Box>
                {element}
            </SpaceBetween>
        </Box>
    }

    const uploadData = async () => {
        if(inputFiles?.length>0){
            configureFlexicoS3()
            let sessionId = uuidv4()
            let level = 'public'
            let prefix = 'flexico/'
            let s3_config = getFlexicoS3Config()
            let bucket_name = s3_config.S3.aws_user_files_s3_bucket
            let filePaths = []
            let fileTypes = []
            for(let i=0;i<inputFiles.length;i++){
                let fileName = inputFiles[i].name
                let fileData = await inputFiles[i].arrayBuffer();
                setEnableTrigger(false)
                setShowLoadingIcon(true)
                let folder = `source/${sessionId}/${fileName}`
                await Storage.vault.put(folder, fileData, {
                    tagging: `userId=${userId}&app_id:${id}&flex_id:${fid}&type=${inputFiles[0].type}`,
                    level: level,
                    customPrefix: { public: prefix },
                    acl: 'bucket-owner-full-control'
                }).then(result => {
                    filePaths.push(`${prefix}${result?.key}`)
                    fileTypes.push(inputFiles[i].type)
                }, function (error) {
        
                })
            }
            var tenYearFromNow = new Date();
            tenYearFromNow.setFullYear(tenYearFromNow.getFullYear() + 10);
            createFlexicoTransaction({
                fileId: sessionId,
                flexId: fid,
                appId: id,
                filePath: filePaths.join(','),
                fileExt: fileTypes.join(','),
                status: FLEXICO_TRANSACTION_STATUS.UPLOADED,
                createdTimestamp: Date.now().toString(),
                lastUpdatedTimestamp: Date.now().toString(),
                uploadedTimestamp: Date.now().toString(),
                uploadedBy: userId,
                bucketName: bucket_name,
                ttl: parseInt(tenYearFromNow / 1000).toString(),
                parameters: JSON.stringify(uploadParameters)
            }, function onSuccess(response) {
                setFileReference(sessionId)
                setShowSuccessModal(true)
                setShowLoadingIcon(false)
                setEnableTrigger(true)
            }, function onFailure(error) {
                setErrorMessage(`File was uploaded successfully. But, there was an issue processing, fileId : ${sessionId}`)
                setShowLoadingIcon(false)
                setEnableTrigger(true)
            })

        }else{
            triggerContico()
        }
        
    }


    const triggerContico = () => {
        setShowLoadingIcon(true)
        setEnableTrigger(false)
        let sessionId = uuidv4()
        var tenYearFromNow = new Date();
        tenYearFromNow.setFullYear(tenYearFromNow.getFullYear() + 10);
        createFlexicoTransaction({
            fileId: sessionId,
            flexId: fid,
            appId: id,
            status: FLEXICO_TRANSACTION_STATUS.TRIGGER_REQUEST,
            createdTimestamp: Date.now().toString(),
            lastUpdatedTimestamp: Date.now().toString(),
            uploadedTimestamp: Date.now().toString(),
            uploadedBy: userId,
            ttl: parseInt(tenYearFromNow / 1000).toString(),
            parameters: JSON.stringify(uploadParameters)
        }, function onSuccess(response) {
            setFileReference(sessionId)
            setShowSuccessModal(true)
            setShowLoadingIcon(false)
            setEnableTrigger(true)
        }, function onFailure(error) {
            setErrorMessage(`There was an issue processing, fileId : ${sessionId}`)
            setShowLoadingIcon(false)
            setEnableTrigger(true)
        })
    }

    const parametersView = (
        <div key="params" className='params'>
                            <ColumnLayout columns={2} key="params">
                                {

                                    flexicoTemplateData.hasOwnProperty('parameters') && flexicoTemplateData['parameters'] ? <>
                                        {
                                            (Array.from(flexicoTemplateData.parameters).map((value, index) => {
                                                return getParameterItems(value, index);
                                            }))
                                        }
                                    </> : <></>
                                }
                            </ColumnLayout>
                        </div>
    )

    const uploadOrTriggerContent = () => {
        if(flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) && 
        (flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file with parameters' || flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file only')){
            return <SpaceBetween size='l'>
            
            {showLoadingIcon ? (
                <Box>
                    <Spinner size="normal"></Spinner>
                    {loadingMessage}
                </Box>) : ''}
            <Container
            >
                    <Grid disableGutters 
                    gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                        
                        <div >
                            {showFileNotSeleted ? <Alert type='error'>File Not Selected</Alert> : ''}
                            {
                                showUploadBtn ? <FileUpload
                                    onChange={({ detail }) => setInputFiles(detail.value)}
                                    value={inputFiles}
                                    i18nStrings={{
                                        uploadButtonText: e =>
                                            e ? "Choose files" : "Choose file",
                                        dropzoneText: e =>
                                            e
                                                ? "Drop files to generate"
                                                : "Drop file to generate",
                                        removeFileAriaLabel: e =>
                                            `Remove file ${e + 1}`,
                                        limitShowFewer: "Show fewer files",
                                        limitShowMore: "Show more files",
                                        errorIconAriaLabel: "Error"
                                    }}
                                    showFileLastModified
                                    showFileSize
                                    showFileThumbnail
                                    multiple
                                    accept={supportedFormats}
                                    constraintText="upload input file"
                                /> : <Box></Box>
                            }


                            {errorMessage ? <Alert statusIconAriaLabel="Error"
                                type="error"
                                header="Upload restricted">
                                {errorMessage} </Alert> : ''}
                        </div>
                        {
                            flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] != 'Upload file only' ? 
                            parametersView : <></>
                        }
                    </Grid>
            </Container>
            <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={uploadData} disabled={!enableTrigger} >Upload/Execute</Button>
                </SpaceBetween>
            </div>
        </SpaceBetween>
        }else if(flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) 
        && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Parameters only'){
            return <SpaceBetween size='l'>
                {
                    parametersView
                }
                <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={() => triggerContico()} disabled={!enableTrigger} >Execute</Button>
                </SpaceBetween>
            </div>
            </SpaceBetween>
        }
        else { 
            return <SpaceBetween size='l'>
                <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={() => triggerContico()} disabled={!enableTrigger} >Execute</Button>
                    {errorMessage ? <Alert statusIconAriaLabel="Error"
                                type="error"
                                header="Triggger restricted">
                                {errorMessage} </Alert> : ''}
                </SpaceBetween>
            </div>
            </SpaceBetween>
        }
    }

    const content = (
            <div>

                <SpaceBetween size='l'>
                <Container
                header={
                    <Header
                        variant='h2'
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant='normal' onClick={() => { history.push(`/App/${id}/flexico/${fid}/history`) }}>
                                    View History
                                </Button>
                            </SpaceBetween>
                        }
                        description=""
                    > Execution history


                    </Header>
                }
            >
            </Container>
            {
                isAuthorized?
                    uploadOrTriggerContent():
                    <>
                    {errorMessage ? <Alert statusIconAriaLabel="Error"
                                type="error"
                                header="Trigger restricted">
                                {errorMessage} </Alert> : ''}
                                </>
                }
                </SpaceBetween>
               

                <Modal
                    visible={showSuccessModal}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={ModalOkClick}>Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="File Upload Status"
                >
                    <Box>
                        <SpaceBetween size='m' direction='vertical'>
                            <p>
                                <Icon variant="success" size='medium' name='status-positive'></Icon>
                                &nbsp; &nbsp;
                                Your trigger was successful. <br></br>
                                File reference : {fileReference}
                            </p>

                        </SpaceBetween>

                    </Box>

                    <br></br>

                </Modal>
            </div>
    )
    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/flexico/${fid}/upload`} id={id} fid={fid} process={flexicoTemplateData} back={backUrl} />}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    );
}